const styledTheme = {
  palette: {
    backgroundGrey: '#F5F6F8',
    blue: '#041753',
    backgroundBlue: '#0E2D7B',
    grey: '#DBDDE6',
    lightBlue: '#6DC4F2',
    lightBlue10: '#6DC4F21A',
    lightBlue20: '#6DC4F233',
    lightBlue50: '#C7EBFF',
    lightBlue60: '#6DC4F299',
    lightBlueBack: '#D3EDFB',
    primaryLightestBlue: '#FAFAFC',
    link: '#0552D6',
    purple: '#AD9EE8',
    purple10: '#AD9EE81A',
    green: '#66D893',
    green40: '#66D89366',
    darkGreen: '#78BF26',
    pink: '#F99EC4',
    pink40: '#F99EC466',
    pink15: '#F99EC426',
    lightPink: '#FDE2ED',
    yellow: '#FFF002',
    warning: '#FFD602',
    warningBack: '#FFF7CC',
    yellow50: '#FFF4BD',
    error: '#DF1540',
    errorBack: '#F9D0D9',
    success: '#66D893',
    successBack: '#D1F3DF',
    black: '#000000',
    white: '#FFFFFF',
    light: '#FBFBFB',
    border: '#E6E8EE',
    ui90: '#041753E6',
    ui90Solid: '#1D2E64',
    ui80: '#041753CC',
    ui80Solid: '#364575',
    ui70: '#041753B3',
    ui70Solid: '#4F5D87',
    ui60: '#04175399',
    ui60Solid: '#687498',
    ui50: '#04175380',
    ui50Solid: '#818BA9',
    ui40: '#04175366',
    ui40Solid: '#9BA2BA',
    ui30: '#0417534D',
    ui30Solid: '#B4B9CB',
    ui20: '#04175333',
    ui20Solid: '#CDD1DD',
    ui15Solid: '#D9DCE5',
    ui10: '#0417531A',
    ui10Solid: '#E6E8EE',
    ui5: '#0417530D',
    ui5Solid: '#F2F3F6',
    ui4Solid: '#F5F6F8',
    transparent: 'transparent',
    energy1: '#33a357',
    energy2: '#79b752',
    energy3: '#c3d545',
    energy4: '#fff12c',
    energy5: '#edb731',
    energy6: '#d66f2c',
    energy7: '#cc232a',
  },
  screenSize: {
    sizeXS: '360px',
    sizeSM: '600px',
    sizeMD: '1024px',
    sizeLG: '1440px',
    sizeXL: '1920px',
  },
  fontSize: {
    root: '16px',
  },
  zIndex: {
    root: 0,
    select: 400,
    comparisonBanner: 450,
    chatBubble: 460,
    sideDrawer: 500,
    header: 600,
    modal: 99999,
    pageLoader: 900,
    toast: 999999,
  },
  borderRadius: {
    normal: '8px',
    large: '12px',
  },
  boxShadow: {
    button: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    card: '-10px 10px 30px rgba(0, 0, 0, 0.1)',
    bar: 'inset 0px -1px 0px #E6E8EE, inset 0px 1px 0px #E6E8EE',
    select: '0px 10px 30px rgba(0, 14, 82, 0.1)',
    header: '-4px 4px 30px rgba(0, 14, 82, 0.05)',
    modal: '-10px 10px 30px rgba(0, 0, 0, 0.1)',
    expandable: '0px 4px 30px rgba(0, 0, 0, 0.1)',
    comparisonBanner: '0px -4px 20px rgba(0, 14, 82, 0.1)',
    simpleSubtle: '0px 18px 34px 0px rgba(0, 14, 82, 0.04)',
    shadowToTop: '0px -4px 16px 0px #04175314',
  },
  navBarHeight: {
    small: '56px',
    large: '64px',
    dutyFreeFrontPageSmall: '90px',
    dutyFreeFrontPageLarge: '114px',
  },
}

export default styledTheme
