/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddInsuranceRequest,
  Address,
  AppleTokenCreateRequest,
  AppleTokenResponse,
  Business,
  BusinessInvoicingEmail,
  BusinessInvoicingEmailRequest,
  BusinessMember,
  BusinessMemberRequest,
  BusinessRegistry,
  BusinessRequest,
  Category,
  Checkout,
  CheckoutDeliveryRequest,
  CheckoutMergeRequest,
  CheckoutRequest,
  CheckoutSalesType,
  CreateAddress,
  CreateAddressRequest,
  CreateBusinessError,
  CreateBusinessMemberRequest,
  CreateBusinessRequest,
  Credit,
  CreditApplication,
  CreditApplicationRequest,
  DeliverySlot,
  DutyFreeCheckoutInfo,
  DutyFreeCheckoutInfoRequest,
  ElkoUser,
  ElkoUserRequest,
  EmailRequest,
  EmailRequestSerialierRequest,
  FieldType,
  FlashShelfLabelRequest,
  GoogleTokenCreate,
  GoogleTokenCreateRequest,
  Login,
  LoginRequest,
  MailListSignup,
  MailListSignupRequest,
  Order,
  PaginatedBusinessList,
  PaginatedCheckoutList,
  PaginatedOrderList,
  PaginatedProductListList,
  PaginatedUserPriceList,
  PaginatedVariantListList,
  PagoLoanCalculation,
  PagoLoanOptions,
  PatchedBusinessMemberRequest,
  PatchedBusinessRequest,
  PatchedCheckoutRequest,
  PatchedCreditApplicationRequest,
  PatchedElkoUserRequest,
  PatchedEmailRequest,
  PatchedUpdateAddressRequest,
  PatchedWishlistItemRequest,
  PatchedWishlistRequest,
  PickupLocation,
  Postbox,
  ProductDetail,
  ProductType,
  ProductWorkshop,
  Registry,
  RestockSubscription,
  ShippingMethod,
  SyncMessage,
  SyncMessageRequest,
  Terms,
  TermsRequest,
  TokenCreate,
  TokenCreateRequest,
  TokenLoginRequest,
  Turnover,
  UpdateAddress,
  UpdateAddressRequest,
  User,
  UserBusinessRequest,
  UserPrice,
  UserVerifyRequest,
  Variant,
  VariantDetail,
  VariantPriceHistory,
  VerifoneCardPaymentInputRequest,
  VerifoneCreateJWTResponse,
  VerifoneJWTLookupInputRequest,
  VerifoneJWTLookupResponse,
  VerifonePaymentToken,
  VerifoneStoreCardInputRequest,
  VerifoneTokenPaymentInputRequest,
  VerifoneWalletPaymentInputRequest,
  VirtualCard,
  VirtualCardRequest,
  Warehouse,
  Wishlist,
  WishlistEncoderInputRequest,
  WishlistItem,
  WishlistItemEdit,
  WishlistItemEditRequest,
  WishlistRequest,
} from './data-contracts'
import {ContentType, HttpClient, RequestParams} from './http-client'

export class Api<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags addresses
   * @name AddressesList
   * @request GET:/api/addresses/
   * @secure
   */
  addressesList = (params: RequestParams = {}) =>
    this.request<Address[], any>({
      path: `/api/addresses/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags addresses
   * @name AddressesCreate
   * @request POST:/api/addresses/
   * @secure
   */
  addressesCreate = (data: CreateAddressRequest, params: RequestParams = {}) =>
    this.request<CreateAddress, any>({
      path: `/api/addresses/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags addresses
   * @name AddressesUpdate
   * @request PUT:/api/addresses/{id}/
   * @secure
   */
  addressesUpdate = (
    id: number,
    data: UpdateAddressRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateAddress, any>({
      path: `/api/addresses/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags addresses
   * @name AddressesPartialUpdate
   * @request PATCH:/api/addresses/{id}/
   * @secure
   */
  addressesPartialUpdate = (
    id: number,
    data: PatchedUpdateAddressRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateAddress, any>({
      path: `/api/addresses/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags addresses
   * @name AddressesDestroy
   * @request DELETE:/api/addresses/{id}/
   * @secure
   */
  addressesDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/addresses/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags business-members
   * @name BusinessMembersRetrieve
   * @request GET:/api/business-members/{id}/
   * @secure
   */
  businessMembersRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<BusinessMember, any>({
      path: `/api/business-members/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags business-members
   * @name BusinessMembersUpdate
   * @request PUT:/api/business-members/{id}/
   * @secure
   */
  businessMembersUpdate = (
    id: number,
    data: BusinessMemberRequest,
    params: RequestParams = {},
  ) =>
    this.request<BusinessMember, any>({
      path: `/api/business-members/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags business-members
   * @name BusinessMembersPartialUpdate
   * @request PATCH:/api/business-members/{id}/
   * @secure
   */
  businessMembersPartialUpdate = (
    id: number,
    data: PatchedBusinessMemberRequest,
    params: RequestParams = {},
  ) =>
    this.request<BusinessMember, any>({
      path: `/api/business-members/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags business-members
   * @name BusinessMembersDestroy
   * @request DELETE:/api/business-members/{id}/
   * @secure
   */
  businessMembersDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/business-members/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags business-registry
   * @name BusinessRegistryRetrieve
   * @request GET:/api/business-registry/{ssn}/
   * @secure
   */
  businessRegistryRetrieve = (ssn: string, params: RequestParams = {}) =>
    this.request<BusinessRegistry, any>({
      path: `/api/business-registry/${ssn}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesList
   * @request GET:/api/businesses/
   * @secure
   */
  businessesList = (query?: {page?: number}, params: RequestParams = {}) =>
    this.request<PaginatedBusinessList, any>({
      path: `/api/businesses/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesCreate
   * @request POST:/api/businesses/
   * @secure
   */
  businessesCreate = (
    data: CreateBusinessRequest,
    params: RequestParams = {},
  ) =>
    this.request<Business, CreateBusinessError>({
      path: `/api/businesses/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesRetrieve
   * @request GET:/api/businesses/{id}/
   * @secure
   */
  businessesRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<Business, any>({
      path: `/api/businesses/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesUpdate
   * @request PUT:/api/businesses/{id}/
   * @secure
   */
  businessesUpdate = (
    id: number,
    data: BusinessRequest,
    params: RequestParams = {},
  ) =>
    this.request<Business, any>({
      path: `/api/businesses/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesPartialUpdate
   * @request PATCH:/api/businesses/{id}/
   * @secure
   */
  businessesPartialUpdate = (
    id: number,
    data: PatchedBusinessRequest,
    params: RequestParams = {},
  ) =>
    this.request<Business, any>({
      path: `/api/businesses/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesDestroy
   * @request DELETE:/api/businesses/{id}/
   * @secure
   */
  businessesDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/businesses/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesCreditRetrieve
   * @request GET:/api/businesses/{id}/credit/
   * @secure
   */
  businessesCreditRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<Credit, any>({
      path: `/api/businesses/${id}/credit/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesInvoicingEmailsList
   * @request GET:/api/businesses/{id}/invoicing_emails/
   * @secure
   */
  businessesInvoicingEmailsList = (id: number, params: RequestParams = {}) =>
    this.request<BusinessInvoicingEmail[], any>({
      path: `/api/businesses/${id}/invoicing_emails/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesInvoicingEmailsCreate
   * @request POST:/api/businesses/{id}/invoicing_emails/
   * @secure
   */
  businessesInvoicingEmailsCreate = (
    id: number,
    data: BusinessInvoicingEmailRequest,
    params: RequestParams = {},
  ) =>
    this.request<BusinessInvoicingEmail[], any>({
      path: `/api/businesses/${id}/invoicing_emails/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesInvoicingEmailsDestroy
   * @request DELETE:/api/businesses/{id}/invoicing_emails/{emailId}/
   * @secure
   */
  businessesInvoicingEmailsDestroy = (
    emailId: string,
    id: number,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/businesses/${id}/invoicing_emails/${emailId}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesMembersList
   * @request GET:/api/businesses/{id}/members/
   * @secure
   */
  businessesMembersList = (id: number, params: RequestParams = {}) =>
    this.request<BusinessMember[], any>({
      path: `/api/businesses/${id}/members/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags businesses
   * @name BusinessesMembersCreate
   * @request POST:/api/businesses/{id}/members/
   * @secure
   */
  businessesMembersCreate = (
    id: number,
    data: CreateBusinessMemberRequest,
    params: RequestParams = {},
  ) =>
    this.request<BusinessMember[], any>({
      path: `/api/businesses/${id}/members/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Display all categories with total product count
   *
   * @tags categories
   * @name CategoriesList
   * @request GET:/api/categories/
   * @secure
   */
  categoriesList = (
    query?: {channel?: 'dutyfree' | 'webshop'},
    params: RequestParams = {},
  ) =>
    this.request<Category[], any>({
      path: `/api/categories/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * @description Display all categories with total product count
   *
   * @tags categories
   * @name CategoriesRetrieve
   * @request GET:/api/categories/{id}/
   * @secure
   */
  categoriesRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<Category, any>({
      path: `/api/categories/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsList
   * @request GET:/api/checkouts/
   * @secure
   */
  checkoutsList = (
    query?: {limit?: number; offset?: number},
    params: RequestParams = {},
  ) =>
    this.request<PaginatedCheckoutList, any>({
      path: `/api/checkouts/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsRetrieve
   * @request GET:/api/checkouts/{token}/
   * @secure
   */
  checkoutsRetrieve = (
    token: string | 'current',
    query?: {channel?: string},
    params: RequestParams = {},
  ) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsUpdate
   * @request PUT:/api/checkouts/{token}/
   * @secure
   */
  checkoutsUpdate = (
    token: string | 'current',
    data: CheckoutRequest,
    query?: {channel?: string},
    params: RequestParams = {},
  ) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/`,
      method: 'PUT',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsPartialUpdate
   * @request PATCH:/api/checkouts/{token}/
   * @secure
   */
  checkoutsPartialUpdate = (
    token: string | 'current',
    data: PatchedCheckoutRequest,
    query?: {channel?: string},
    params: RequestParams = {},
  ) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/`,
      method: 'PATCH',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsDeliveryCreate
   * @request POST:/api/checkouts/{token}/delivery/
   * @secure
   */
  checkoutsDeliveryCreate = (
    token: string | 'current',
    data: CheckoutDeliveryRequest,
    query?: {channel?: string},
    params: RequestParams = {},
  ) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/delivery/`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsInsuranceUpdate
   * @request PUT:/api/checkouts/{token}/insurance/
   * @secure
   */
  checkoutsInsuranceUpdate = (
    token: string | 'current',
    data: AddInsuranceRequest[],
    query?: {channel?: string},
    params: RequestParams = {},
  ) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/insurance/`,
      method: 'PUT',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsLoanCalculatorRetrieve
   * @request GET:/api/checkouts/{token}/loan_calculator/
   * @secure
   */
  checkoutsLoanCalculatorRetrieve = (
    token: string | 'current',
    query: {channel?: string; installments: number},
    params: RequestParams = {},
  ) =>
    this.request<PagoLoanCalculation, any>({
      path: `/api/checkouts/${token}/loan_calculator/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsLoanOptionsRetrieve
   * @request GET:/api/checkouts/{token}/loan_options/
   * @secure
   */
  checkoutsLoanOptionsRetrieve = (
    token: string | 'current',
    query?: {channel?: string},
    params: RequestParams = {},
  ) =>
    this.request<PagoLoanOptions, any>({
      path: `/api/checkouts/${token}/loan_options/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsMergeUpdate
   * @request PUT:/api/checkouts/{token}/merge/
   * @secure
   */
  checkoutsMergeUpdate = (
    token: string | 'current',
    data: CheckoutMergeRequest,
    query?: {channel?: string},
    params: RequestParams = {},
  ) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/merge/`,
      method: 'PUT',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsSalesTypeList
   * @request GET:/api/checkouts/{token}/sales_type/
   * @secure
   */
  checkoutsSalesTypeList = (
    token: string | 'current',
    query?: {channel?: string},
    params: RequestParams = {},
  ) =>
    this.request<CheckoutSalesType[], any>({
      path: `/api/checkouts/${token}/sales_type/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsValitorKortalanRetrieve
   * @request GET:/api/checkouts/{token}/valitor_kortalan/
   * @secure
   */
  checkoutsValitorKortalanRetrieve = (
    token: string | 'current',
    query?: {interestFree?: boolean; payments?: number},
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/checkouts/${token}/valitor_kortalan/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * @description Handle CreditApplication item
   *
   * @tags credit-applications
   * @name CreditApplicationsList
   * @request GET:/api/credit-applications/
   * @secure
   */
  creditApplicationsList = (params: RequestParams = {}) =>
    this.request<CreditApplication[], any>({
      path: `/api/credit-applications/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * @description Handle CreditApplication item
   *
   * @tags credit-applications
   * @name CreditApplicationsCreate
   * @request POST:/api/credit-applications/
   * @secure
   */
  creditApplicationsCreate = (
    data: CreditApplicationRequest,
    params: RequestParams = {},
  ) =>
    this.request<CreditApplication, any>({
      path: `/api/credit-applications/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Handle CreditApplication item
   *
   * @tags credit-applications
   * @name CreditApplicationsRetrieve
   * @request GET:/api/credit-applications/{id}/
   * @secure
   */
  creditApplicationsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CreditApplication, any>({
      path: `/api/credit-applications/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * @description Handle CreditApplication item
   *
   * @tags credit-applications
   * @name CreditApplicationsUpdate
   * @request PUT:/api/credit-applications/{id}/
   * @secure
   */
  creditApplicationsUpdate = (
    id: number,
    data: CreditApplicationRequest,
    params: RequestParams = {},
  ) =>
    this.request<CreditApplication, any>({
      path: `/api/credit-applications/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Handle CreditApplication item
   *
   * @tags credit-applications
   * @name CreditApplicationsPartialUpdate
   * @request PATCH:/api/credit-applications/{id}/
   * @secure
   */
  creditApplicationsPartialUpdate = (
    id: number,
    data: PatchedCreditApplicationRequest,
    params: RequestParams = {},
  ) =>
    this.request<CreditApplication, any>({
      path: `/api/credit-applications/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Handle CreditApplication item
   *
   * @tags credit-applications
   * @name CreditApplicationsDestroy
   * @request DELETE:/api/credit-applications/{id}/
   * @secure
   */
  creditApplicationsDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/credit-applications/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags delivery-slots
   * @name DeliverySlotsList
   * @request GET:/api/delivery-slots/
   * @secure
   */
  deliverySlotsList = (params: RequestParams = {}) =>
    this.request<DeliverySlot[], any>({
      path: `/api/delivery-slots/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags delivery-slots
   * @name DeliverySlotsRetrieve
   * @request GET:/api/delivery-slots/{id}/
   * @secure
   */
  deliverySlotsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<DeliverySlot, any>({
      path: `/api/delivery-slots/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags dutyfree-checkouts
   * @name DutyfreeCheckoutsRetrieve
   * @request GET:/api/dutyfree-checkouts/{token}/
   * @secure
   */
  dutyfreeCheckoutsRetrieve = (token: string, params: RequestParams = {}) =>
    this.request<DutyFreeCheckoutInfo, any>({
      path: `/api/dutyfree-checkouts/${token}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags dutyfree-checkouts
   * @name DutyfreeCheckoutsUpdate
   * @request PUT:/api/dutyfree-checkouts/{token}/
   * @secure
   */
  dutyfreeCheckoutsUpdate = (
    token: string,
    data: DutyFreeCheckoutInfoRequest,
    params: RequestParams = {},
  ) =>
    this.request<DutyFreeCheckoutInfo, any>({
      path: `/api/dutyfree-checkouts/${token}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags field-types
   * @name FieldTypesList
   * @request GET:/api/field-types/
   * @secure
   */
  fieldTypesList = (params: RequestParams = {}) =>
    this.request<FieldType[], any>({
      path: `/api/field-types/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags orders
   * @name OrdersList
   * @request GET:/api/orders/
   * @secure
   */
  ordersList = (
    query?: {limit?: number; offset?: number},
    params: RequestParams = {},
  ) =>
    this.request<PaginatedOrderList, any>({
      path: `/api/orders/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags orders
   * @name OrdersRetrieve
   * @request GET:/api/orders/{token}/
   * @secure
   */
  ordersRetrieve = (token: string, params: RequestParams = {}) =>
    this.request<Order, any>({
      path: `/api/orders/${token}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorKortalanCallbackRetrieve
   * @request GET:/api/payments/valitor_kortalan/callback
   * @secure
   */
  paymentsValitorKortalanCallbackRetrieve = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/payments/valitor_kortalan/callback`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorKortalanCallbackCreate
   * @request POST:/api/payments/valitor_kortalan/callback
   * @secure
   */
  paymentsValitorKortalanCallbackCreate = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/payments/valitor_kortalan/callback`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayAppleTokensCreate
   * @request POST:/api/payments/valitor_pay/apple_tokens/
   * @secure
   */
  paymentsValitorPayAppleTokensCreate = (
    data: AppleTokenCreateRequest,
    params: RequestParams = {},
  ) =>
    this.request<AppleTokenResponse, any>({
      path: `/api/payments/valitor_pay/apple_tokens/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayCardsList
   * @request GET:/api/payments/valitor_pay/cards/
   * @secure
   */
  paymentsValitorPayCardsList = (params: RequestParams = {}) =>
    this.request<VirtualCard[], any>({
      path: `/api/payments/valitor_pay/cards/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayCardsCreate
   * @request POST:/api/payments/valitor_pay/cards/
   * @secure
   */
  paymentsValitorPayCardsCreate = (
    data: VirtualCardRequest,
    params: RequestParams = {},
  ) =>
    this.request<VirtualCard, any>({
      path: `/api/payments/valitor_pay/cards/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayCardsDestroy
   * @request DELETE:/api/payments/valitor_pay/cards/{id}/
   * @secure
   */
  paymentsValitorPayCardsDestroy = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/payments/valitor_pay/cards/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayCcCallbackRetrieve
   * @request GET:/api/payments/valitor_pay/cc_callback/
   * @secure
   */
  paymentsValitorPayCcCallbackRetrieve = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/payments/valitor_pay/cc_callback/`,
      method: 'GET',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayCcCallbackCreate
   * @request POST:/api/payments/valitor_pay/cc_callback/
   * @secure
   */
  paymentsValitorPayCcCallbackCreate = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/payments/valitor_pay/cc_callback/`,
      method: 'POST',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayGoogleTokensCreate
   * @request POST:/api/payments/valitor_pay/google_tokens/
   * @secure
   */
  paymentsValitorPayGoogleTokensCreate = (
    data: GoogleTokenCreateRequest,
    params: RequestParams = {},
  ) =>
    this.request<GoogleTokenCreate, any>({
      path: `/api/payments/valitor_pay/google_tokens/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayTokensCreate
   * @request POST:/api/payments/valitor_pay/tokens/
   * @secure
   */
  paymentsValitorPayTokensCreate = (
    data: TokenCreateRequest,
    params: RequestParams = {},
  ) =>
    this.request<TokenCreate, any>({
      path: `/api/payments/valitor_pay/tokens/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayVccCallbackRetrieve
   * @request GET:/api/payments/valitor_pay/vcc_callback/
   * @secure
   */
  paymentsValitorPayVccCallbackRetrieve = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/payments/valitor_pay/vcc_callback/`,
      method: 'GET',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayVccCallbackCreate
   * @request POST:/api/payments/valitor_pay/vcc_callback/
   * @secure
   */
  paymentsValitorPayVccCallbackCreate = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/payments/valitor_pay/vcc_callback/`,
      method: 'POST',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsVerifoneJwtCreate
   * @request POST:/api/payments/verifone/jwt/
   * @secure
   */
  paymentsVerifoneJwtCreate = (params: RequestParams = {}) =>
    this.request<VerifoneCreateJWTResponse, any>({
      path: `/api/payments/verifone/jwt/`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsVerifoneJwtLookupCreate
   * @request POST:/api/payments/verifone/jwt/lookup/
   * @secure
   */
  paymentsVerifoneJwtLookupCreate = (
    data: VerifoneJWTLookupInputRequest,
    params: RequestParams = {},
  ) =>
    this.request<VerifoneJWTLookupResponse, any>({
      path: `/api/payments/verifone/jwt/lookup/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsVerifoneTokenList
   * @request GET:/api/payments/verifone/token/
   * @secure
   */
  paymentsVerifoneTokenList = (params: RequestParams = {}) =>
    this.request<VerifonePaymentToken[], any>({
      path: `/api/payments/verifone/token/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsVerifoneTokenCreate
   * @request POST:/api/payments/verifone/token/
   * @secure
   */
  paymentsVerifoneTokenCreate = (
    data: VerifoneStoreCardInputRequest,
    params: RequestParams = {},
  ) =>
    this.request<VerifonePaymentToken, any>({
      path: `/api/payments/verifone/token/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsVerifoneTokenDestroy
   * @request DELETE:/api/payments/verifone/token/{id}/
   * @secure
   */
  paymentsVerifoneTokenDestroy = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/payments/verifone/token/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsVerifoneTransactionCardCreate
   * @request POST:/api/payments/verifone/transaction/card/
   * @secure
   */
  paymentsVerifoneTransactionCardCreate = (
    data: VerifoneCardPaymentInputRequest,
    params: RequestParams = {},
  ) =>
    this.request<any, any>({
      path: `/api/payments/verifone/transaction/card/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsVerifoneTransactionTokenCreate
   * @request POST:/api/payments/verifone/transaction/token/
   * @secure
   */
  paymentsVerifoneTransactionTokenCreate = (
    data: VerifoneTokenPaymentInputRequest,
    params: RequestParams = {},
  ) =>
    this.request<any, any>({
      path: `/api/payments/verifone/transaction/token/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsVerifoneTransactionWalletCreate
   * @request POST:/api/payments/verifone/transaction/wallet/
   * @secure
   */
  paymentsVerifoneTransactionWalletCreate = (
    data: VerifoneWalletPaymentInputRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/payments/verifone/transaction/wallet/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags pickup-locations
   * @name PickupLocationsList
   * @request GET:/api/pickup-locations/
   * @secure
   */
  pickupLocationsList = (params: RequestParams = {}) =>
    this.request<PickupLocation[], any>({
      path: `/api/pickup-locations/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pickup-locations
   * @name PickupLocationsRetrieve
   * @request GET:/api/pickup-locations/{id}/
   * @secure
   */
  pickupLocationsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<PickupLocation, any>({
      path: `/api/pickup-locations/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags postboxes
   * @name PostboxesList
   * @request GET:/api/postboxes/
   * @secure
   */
  postboxesList = (params: RequestParams = {}) =>
    this.request<Postbox[], any>({
      path: `/api/postboxes/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags prices
   * @name PricesList
   * @request GET:/api/prices/
   * @secure
   */
  pricesList = (
    query: {page?: number; page_size?: number; sku: string[]},
    params: RequestParams = {},
  ) =>
    this.request<PaginatedUserPriceList, any>({
      path: `/api/prices/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags prices
   * @name PricesRetrieve
   * @request GET:/api/prices/{variant_sku}/
   * @secure
   */
  pricesRetrieve = (variantSku: string, params: RequestParams = {}) =>
    this.request<UserPrice, any>({
      path: `/api/prices/${variantSku}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags product-types
   * @name ProductTypesList
   * @request GET:/api/product-types/
   * @secure
   */
  productTypesList = (params: RequestParams = {}) =>
    this.request<ProductType[], any>({
      path: `/api/product-types/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags product-types
   * @name ProductTypesRetrieve
   * @request GET:/api/product-types/{slug}/
   * @secure
   */
  productTypesRetrieve = (slug: string, params: RequestParams = {}) =>
    this.request<ProductType, any>({
      path: `/api/product-types/${slug}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags product-variants
   * @name ProductVariantsList
   * @request GET:/api/product-variants/
   * @secure
   */
  productVariantsList = (
    query?: {
      channel?: 'dutyfree' | 'webshop'
      page?: number
      page_size?: number
      sku?: string[]
    },
    params: RequestParams = {},
  ) =>
    this.request<PaginatedVariantListList, any>({
      path: `/api/product-variants/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags product-variants
   * @name ProductVariantsRetrieve
   * @request GET:/api/product-variants/{sku}/
   * @secure
   */
  productVariantsRetrieve = (sku: string, params: RequestParams = {}) =>
    this.request<VariantDetail, any>({
      path: `/api/product-variants/${sku}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags product-variants
   * @name ProductVariantsPriceHistoryList
   * @request GET:/api/product-variants/{sku}/price-history/
   * @secure
   */
  productVariantsPriceHistoryList = (
    sku: string,
    query?: {channel?: 'dutyfree' | 'webshop'},
    params: RequestParams = {},
  ) =>
    this.request<VariantPriceHistory[], any>({
      path: `/api/product-variants/${sku}/price-history/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags product-variants
   * @name ProductVariantsSubscriptionRetrieve
   * @request GET:/api/product-variants/{sku}/subscription/
   * @secure
   */
  productVariantsSubscriptionRetrieve = (
    sku: string,
    params: RequestParams = {},
  ) =>
    this.request<RestockSubscription, any>({
      path: `/api/product-variants/${sku}/subscription/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags product-variants
   * @name ProductVariantsSubscriptionCreate
   * @request POST:/api/product-variants/{sku}/subscription/
   * @secure
   */
  productVariantsSubscriptionCreate = (
    sku: string,
    params: RequestParams = {},
  ) =>
    this.request<RestockSubscription, any>({
      path: `/api/product-variants/${sku}/subscription/`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags product-variants
   * @name ProductVariantsMultiList
   * @request GET:/api/product-variants/multi/
   * @secure
   */
  productVariantsMultiList = (
    query?: {channel?: 'dutyfree' | 'webshop'; sku?: string[]},
    params: RequestParams = {},
  ) =>
    this.request<VariantDetail[], any>({
      path: `/api/product-variants/multi/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags product-workshop
   * @name ProductWorkshopList
   * @request GET:/api/product-workshop/
   * @secure
   */
  productWorkshopList = (params: RequestParams = {}) =>
    this.request<ProductWorkshop[], any>({
      path: `/api/product-workshop/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags product-workshop
   * @name ProductWorkshopRetrieve
   * @request GET:/api/product-workshop/{id}/
   * @secure
   */
  productWorkshopRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<ProductWorkshop, any>({
      path: `/api/product-workshop/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags products
   * @name ProductsList
   * @request GET:/api/products/
   * @secure
   */
  productsList = (
    query?: {
      channel?: 'dutyfree' | 'webshop'
      id?: number[]
      page?: number
      page_size?: number
    },
    params: RequestParams = {},
  ) =>
    this.request<PaginatedProductListList, any>({
      path: `/api/products/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags products
   * @name ProductsRetrieve
   * @request GET:/api/products/{id}/
   * @secure
   */
  productsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<ProductDetail, any>({
      path: `/api/products/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags products
   * @name ProductsFlashShelfLabelCreate
   * @request POST:/api/products/{id}/flash_shelf_label/
   * @secure
   */
  productsFlashShelfLabelCreate = (
    id: number,
    data: FlashShelfLabelRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/products/${id}/flash_shelf_label/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags receipts
   * @name ReceiptsList
   * @request GET:/api/receipts/
   * @secure
   */
  receiptsList = (query?: {ssn?: string}, params: RequestParams = {}) =>
    this.request<object[], any>({
      path: `/api/receipts/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags receipts
   * @name ReceiptsRetrieve
   * @request GET:/api/receipts/{id}/
   * @secure
   */
  receiptsRetrieve = (
    id: string,
    query?: {ssn?: string},
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/receipts/${id}/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags receipts
   * @name ReceiptsPdfRetrieve
   * @request GET:/api/receipts/{id}/pdf/
   * @secure
   */
  receiptsPdfRetrieve = (
    id: string,
    query?: {ssn?: string},
    params: RequestParams = {},
  ) =>
    this.request<File, any>({
      path: `/api/receipts/${id}/pdf/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags receipts
   * @name ReceiptsPdfSendEmailCreate
   * @request POST:/api/receipts/{id}/pdf/send-email/
   * @secure
   */
  receiptsPdfSendEmailCreate = (
    id: string,
    data: EmailRequestSerialierRequest,
    query?: {ssn?: string},
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/receipts/${id}/pdf/send-email/`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags receipts
   * @name ReceiptsTurnoverList
   * @request GET:/api/receipts/turnover/
   * @secure
   */
  receiptsTurnoverList = (query?: {ssn?: string}, params: RequestParams = {}) =>
    this.request<Turnover[], any>({
      path: `/api/receipts/turnover/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags registry
   * @name RegistryVerifyRetrieve
   * @request GET:/api/registry/{ssn}/verify/
   * @secure
   */
  registryVerifyRetrieve = (ssn: string, params: RequestParams = {}) =>
    this.request<Registry, any>({
      path: `/api/registry/${ssn}/verify/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
   *
   * @tags schema
   * @name SchemaRetrieve
   * @request GET:/api/schema/
   * @secure
   */
  schemaRetrieve = (
    query?: {format?: 'json' | 'yaml'; lang?: 'en' | 'is'},
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/schema/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags shipping-methods
   * @name ShippingMethodsList
   * @request GET:/api/shipping-methods/
   * @secure
   */
  shippingMethodsList = (params: RequestParams = {}) =>
    this.request<ShippingMethod[], any>({
      path: `/api/shipping-methods/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags shipping-methods
   * @name ShippingMethodsRetrieve
   * @request GET:/api/shipping-methods/{id}/
   * @secure
   */
  shippingMethodsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<ShippingMethod, any>({
      path: `/api/shipping-methods/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags sync-messages
   * @name SyncMessagesCreate
   * @request POST:/api/sync-messages/
   * @secure
   */
  syncMessagesCreate = (data: SyncMessageRequest, params: RequestParams = {}) =>
    this.request<SyncMessage, any>({
      path: `/api/sync-messages/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags trigger-restock-notifications
   * @name TriggerRestockNotificationsCreate
   * @request POST:/api/trigger-restock-notifications
   * @secure
   */
  triggerRestockNotificationsCreate = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/trigger-restock-notifications`,
      method: 'POST',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersList
   * @request GET:/api/users/
   * @secure
   */
  usersList = (params: RequestParams = {}) =>
    this.request<ElkoUser[], any>({
      path: `/api/users/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersRetrieve
   * @request GET:/api/users/{id}/
   * @secure
   */
  usersRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<ElkoUser, any>({
      path: `/api/users/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersSettingsRetrieve
   * @request GET:/api/users/{id}/settings/
   * @secure
   */
  usersSettingsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/users/${id}/settings/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersSettingsCreate
   * @request POST:/api/users/{id}/settings/
   * @secure
   */
  usersSettingsCreate = (
    id: number,
    data: Record<string, any>,
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/users/${id}/settings/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersSettingsPartialUpdate
   * @request PATCH:/api/users/{id}/settings/
   * @secure
   */
  usersSettingsPartialUpdate = (
    id: number,
    data: Record<string, any>,
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/users/${id}/settings/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Verify user, get SSN and other information. If the user has been verified before, override the previous verification with the new one.
   *
   * @tags users
   * @name UsersVerifyCreate
   * @request POST:/api/users/{id}/verify/
   * @secure
   */
  usersVerifyCreate = (
    id: number,
    data: UserVerifyRequest,
    params: RequestParams = {},
  ) =>
    this.request<User, any>({
      path: `/api/users/${id}/verify/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersLoginCreate
   * @request POST:/api/users/login/
   * @secure
   */
  usersLoginCreate = (data: LoginRequest, params: RequestParams = {}) =>
    this.request<Login, any>({
      path: `/api/users/login/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeRetrieve
   * @request GET:/api/users/me/
   * @secure
   */
  usersMeRetrieve = (params: RequestParams = {}) =>
    this.request<ElkoUser, any>({
      path: `/api/users/me/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeUpdate
   * @request PUT:/api/users/me/
   * @secure
   */
  usersMeUpdate = (data: ElkoUserRequest, params: RequestParams = {}) =>
    this.request<ElkoUser, any>({
      path: `/api/users/me/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMePartialUpdate
   * @request PATCH:/api/users/me/
   * @secure
   */
  usersMePartialUpdate = (
    data: PatchedElkoUserRequest,
    params: RequestParams = {},
  ) =>
    this.request<ElkoUser, any>({
      path: `/api/users/me/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeAddressesList
   * @request GET:/api/users/me/addresses/
   * @secure
   */
  usersMeAddressesList = (params: RequestParams = {}) =>
    this.request<Address[], any>({
      path: `/api/users/me/addresses/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeAddressesCreate
   * @request POST:/api/users/me/addresses/
   * @secure
   */
  usersMeAddressesCreate = (
    data: CreateAddressRequest,
    params: RequestParams = {},
  ) =>
    this.request<Address[], any>({
      path: `/api/users/me/addresses/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeBusinessRetrieve
   * @request GET:/api/users/me/business/
   * @secure
   */
  usersMeBusinessRetrieve = (params: RequestParams = {}) =>
    this.request<Business, any>({
      path: `/api/users/me/business/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeBusinessUpdate
   * @request PUT:/api/users/me/business/
   * @secure
   */
  usersMeBusinessUpdate = (
    data: UserBusinessRequest,
    params: RequestParams = {},
  ) =>
    this.request<Business, any>({
      path: `/api/users/me/business/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeEmailCreate
   * @request POST:/api/users/me/email/
   * @secure
   */
  usersMeEmailCreate = (data: EmailRequest, params: RequestParams = {}) =>
    this.request<ElkoUser, any>({
      path: `/api/users/me/email/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeEmailUpdate
   * @request PUT:/api/users/me/email/
   * @secure
   */
  usersMeEmailUpdate = (data: EmailRequest, params: RequestParams = {}) =>
    this.request<ElkoUser, any>({
      path: `/api/users/me/email/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeEmailPartialUpdate
   * @request PATCH:/api/users/me/email/
   * @secure
   */
  usersMeEmailPartialUpdate = (
    data: PatchedEmailRequest,
    params: RequestParams = {},
  ) =>
    this.request<ElkoUser, any>({
      path: `/api/users/me/email/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeMailingListSignupCreate
   * @request POST:/api/users/me/mailing-list/signup
   * @secure
   */
  usersMeMailingListSignupCreate = (
    data: MailListSignupRequest,
    params: RequestParams = {},
  ) =>
    this.request<MailListSignup, any>({
      path: `/api/users/me/mailing-list/signup`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeTermsRetrieve
   * @request GET:/api/users/me/terms/
   * @secure
   */
  usersMeTermsRetrieve = (params: RequestParams = {}) =>
    this.request<Terms, any>({
      path: `/api/users/me/terms/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersMeTermsCreate
   * @request POST:/api/users/me/terms/
   * @secure
   */
  usersMeTermsCreate = (data: TermsRequest, params: RequestParams = {}) =>
    this.request<Terms, any>({
      path: `/api/users/me/terms/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags users
   * @name UsersTokenLoginCreate
   * @request POST:/api/users/token_login/
   * @secure
   */
  usersTokenLoginCreate = (
    data: TokenLoginRequest,
    params: RequestParams = {},
  ) =>
    this.request<Login, any>({
      path: `/api/users/token_login/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags variants
   * @name VariantsRetrieve
   * @request GET:/api/variants/{sku}/
   * @secure
   */
  variantsRetrieve = (sku: string, params: RequestParams = {}) =>
    this.request<Variant, any>({
      path: `/api/variants/${sku}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags warehouses
   * @name WarehousesList
   * @request GET:/api/warehouses/
   * @secure
   */
  warehousesList = (params: RequestParams = {}) =>
    this.request<Warehouse[], any>({
      path: `/api/warehouses/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags warehouses
   * @name WarehousesRetrieve
   * @request GET:/api/warehouses/{id}/
   * @secure
   */
  warehousesRetrieve = (id: string, params: RequestParams = {}) =>
    this.request<Warehouse, any>({
      path: `/api/warehouses/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlist-items
   * @name WishlistItemsList
   * @request GET:/api/wishlist-items/
   * @secure
   */
  wishlistItemsList = (
    query?: {channel?: 'dutyfree' | 'webshop'},
    params: RequestParams = {},
  ) =>
    this.request<WishlistItem[], any>({
      path: `/api/wishlist-items/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlist-items
   * @name WishlistItemsCreate
   * @request POST:/api/wishlist-items/
   * @secure
   */
  wishlistItemsCreate = (
    data: WishlistItemEditRequest,
    params: RequestParams = {},
  ) =>
    this.request<WishlistItem, any>({
      path: `/api/wishlist-items/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlist-items
   * @name WishlistItemsRetrieve
   * @request GET:/api/wishlist-items/{id}/
   * @secure
   */
  wishlistItemsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<WishlistItem, any>({
      path: `/api/wishlist-items/${id}/`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlist-items
   * @name WishlistItemsUpdate
   * @request PUT:/api/wishlist-items/{id}/
   * @secure
   */
  wishlistItemsUpdate = (
    id: number,
    data: WishlistItemEditRequest,
    params: RequestParams = {},
  ) =>
    this.request<WishlistItemEdit, any>({
      path: `/api/wishlist-items/${id}/`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlist-items
   * @name WishlistItemsPartialUpdate
   * @request PATCH:/api/wishlist-items/{id}/
   * @secure
   */
  wishlistItemsPartialUpdate = (
    id: number,
    data: PatchedWishlistItemRequest,
    params: RequestParams = {},
  ) =>
    this.request<WishlistItem, any>({
      path: `/api/wishlist-items/${id}/`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlist-items
   * @name WishlistItemsDestroy
   * @request DELETE:/api/wishlist-items/{id}/
   * @secure
   */
  wishlistItemsDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/wishlist-items/${id}/`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlists
   * @name WishlistsList
   * @request GET:/api/wishlists/
   * @secure
   */
  wishlistsList = (
    query?: {channel?: 'dutyfree' | 'webshop'},
    params: RequestParams = {},
  ) =>
    this.request<Wishlist[], any>({
      path: `/api/wishlists/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlists
   * @name WishlistsCreate
   * @request POST:/api/wishlists/
   * @secure
   */
  wishlistsCreate = (
    data: WishlistRequest,
    query?: {channel?: 'dutyfree' | 'webshop'},
    params: RequestParams = {},
  ) =>
    this.request<Wishlist, any>({
      path: `/api/wishlists/`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlists
   * @name WishlistsRetrieve
   * @request GET:/api/wishlists/{id}/
   * @secure
   */
  wishlistsRetrieve = (
    id: number,
    query?: {channel?: 'dutyfree' | 'webshop'},
    params: RequestParams = {},
  ) =>
    this.request<Wishlist, any>({
      path: `/api/wishlists/${id}/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlists
   * @name WishlistsUpdate
   * @request PUT:/api/wishlists/{id}/
   * @secure
   */
  wishlistsUpdate = (
    id: number,
    data: WishlistRequest,
    query?: {channel?: 'dutyfree' | 'webshop'},
    params: RequestParams = {},
  ) =>
    this.request<Wishlist, any>({
      path: `/api/wishlists/${id}/`,
      method: 'PUT',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlists
   * @name WishlistsPartialUpdate
   * @request PATCH:/api/wishlists/{id}/
   * @secure
   */
  wishlistsPartialUpdate = (
    id: number,
    data: PatchedWishlistRequest,
    query?: {channel?: 'dutyfree' | 'webshop'},
    params: RequestParams = {},
  ) =>
    this.request<Wishlist, any>({
      path: `/api/wishlists/${id}/`,
      method: 'PATCH',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlists
   * @name WishlistsDestroy
   * @request DELETE:/api/wishlists/{id}/
   * @secure
   */
  wishlistsDestroy = (
    id: number,
    query?: {channel?: 'dutyfree' | 'webshop'},
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/wishlists/${id}/`,
      method: 'DELETE',
      query: query,
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlists
   * @name WishlistsDecodeRetrieve
   * @request GET:/api/wishlists/decode/
   * @secure
   */
  wishlistsDecodeRetrieve = (
    query?: {encoded?: string},
    params: RequestParams = {},
  ) =>
    this.request<Wishlist, any>({
      path: `/api/wishlists/decode/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags wishlists
   * @name WishlistsEncodeCreate
   * @request POST:/api/wishlists/encode/
   * @secure
   */
  wishlistsEncodeCreate = (
    data: WishlistEncoderInputRequest,
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/wishlists/encode/`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
