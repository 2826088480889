import {createGlobalStyle} from 'styled-components'

export const ResetStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  html {
    height: -webkit-fill-available;
  }
  body {
    line-height: 1;
    min-height: -webkit-fill-available;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
    color: #011627;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }
  *, *:before, *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "ElkjopSans", sans-serif;
    background-color: #F2F3F6;
  }
  button, input {
    font-family: "ElkjopSans", sans-serif;
  }
  strong, b {
    font-weight: 700;
  }
  i {
    font-style: italic;
  }
  a {
    color: #0552D6;
    text-decoration: none;
  }

  /* This is for the progress bar, rendered on page loading */
  #nprogress .bar {
    background: #6DC4F2 !important;
  }

  /* This is for react-modal animation */
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.2s;
  }
  .ReactModal__Overlay--after-open {
    opacity: 1;
    /* transform: translateX(0px); */
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
    /* transform: translateX(-100px); */
  }

  .ReactModal__Content {
    transform: translateY(50px) scale(0.96);
    transition: transform 0.2s ease-out;
  }
  .ReactModal__Content--after-open {
    transform: translateY(0px) scale(1);
  }
  .ReactModal__Content--before-close {
    transform: translateY(50px) scale(0.96);
  }

  .ReactModal__Content#animation-slide {
    transform: translateX(100%);
    transition: transform 0.2s ease-out;
  }
  .ReactModal__Content--after-open#animation-slide {
    transform: translateX(0);
  }
  .ReactModal__Content--before-close#animation-slide {
    transform: translateX(100%);
  }

  /* Global icon styles */
  .remixicon-icon {
    display: block;
    transform-origin: 50% 50%;
    transition: fill 0.15s;
  }

  /* Notification Toasts */
  .Toastify__toast-container {

  }

  /* Notification Toasts */
  .Toastify__toast-container--top-right {
    top: 55px;

    @media(min-width: 1024px) {
      top: 96px;
      right: 16px;
    }
  }

  /** Classes for the displayed toast **/
  .Toastify__toast-container {
    z-index: 999999;
  }
  .Toastify__toast {
    background-color: white;
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid #E6E8EE;
  }
  .Toastify__toast--success {
    .Toastify__toast-body {
      color: #66D893;
    }
  }
  .Toastify__toast--warning {
    .Toastify__toast-body {
      color: #FFD602;
    }
  }
  .Toastify__toast--error {
    .Toastify__toast-body {
      color: #DF1540;
    }
  }
  .Toastify__toast-body {
    font-family: "ElkjopSans", sans-serif;
    color: #041753;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 16px;
  }
  .Toastify__close-button > svg {
    color: #818BA9;
  }
  .Toastify__progress-bar {
    background: unset;
    background-color: #6DC4F2;
  }
  .Toastify__progress-bar--default {
    background-color: #6DC4F2;
  }
  .Toastify__progress-bar--info {
    background-color: #6DC4F2;
  }
  .Toastify__progress-bar--success {
    background-color: #66D893;
  }
  .Toastify__progress-bar--warning {
    background-color: #FFD602;
  }
  .Toastify__progress-bar--error {
    background-color: #DF1540;
  }

  .ReactCollapse--collapse {
    transition: height 0.2s;
  }

  // Fix for "Unable to preventDefault inside passive event listener" error
  .slick-list, .slick-track {
    touch-action:pan-y;
  }
`
